import { Link } from '@redwoodjs/router'
import Divider from '@mui/material/Divider'
import Drawer from '@mui/material/Drawer'
import List from '@mui/material/List'
import Box from '@mui/material/Box'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import PermMediaOutlinedIcon from '@mui/icons-material/PhotoSizeSelectActual'
import TimerIcon from '@mui/icons-material/Timer'
import SettingsIcon from '@mui/icons-material/Settings'
import DashboardIcon from '@mui/icons-material/Dashboard'
import PaymentsIcon from '@mui/icons-material/Payments'
import ReceiptIcon from '@mui/icons-material/Receipt'
import EqualizerIcon from '@mui/icons-material/Equalizer'
import LocalOfferIcon from '@mui/icons-material/LocalOffer'
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts'

const categories = [
  {
    id: 'Manage',
    children: [
      {
        id: 'Dashboard',
        icon: <DashboardIcon />,
        active: true,
        url: '/merchants',
      },
      {
        id: 'Account',
        url: '/organizations',
        icon: <ManageAccountsIcon />,
      },
      { id: 'Products', url: '/products', icon: <LocalOfferIcon /> },
      {
        id: 'Orders',
        url: '/orders',
        icon: <PermMediaOutlinedIcon />,
      },
      { id: 'Commissions', url: '/commissions', icon: <ReceiptIcon /> },
    ],
  },
  {
    id: 'Admin',
    children: [
      { id: 'Payouts', url: '/admin/payouts', icon: <PaymentsIcon /> },
      { id: 'Reports', url: '/admin/reports', icon: <EqualizerIcon /> },
      { id: 'Activity Log', url: '/admin/activity-log', icon: <TimerIcon /> },
      { id: 'Settings', url: '/admin/settings', icon: <SettingsIcon /> },
    ],
  },
]

const item = {
  py: '2px',
  px: 3,
  color: 'rgba(255, 255, 255, 0.7)',
  '&:hover, &:focus': {
    bgcolor: 'rgba(255, 255, 255, 0.08)',
  },
}

const itemCategory = {
  boxShadow: '0 -1px 0 rgb(255,255,255,0.1) inset',
  py: 1.5,
  px: 3,
}

export default function Navigator(props) {
  const { ...other } = props

  return (
    <Drawer variant="permanent" {...other}>
      <List disablePadding>
        <ListItem
          sx={{ ...item, ...itemCategory, fontSize: 22, color: '#fff' }}
        >
          Caribshopper
        </ListItem>

        {categories.map(({ id, children }) => (
          <Box key={id} sx={{ bgcolor: '#101F33' }}>
            <ListItem sx={{ py: 2, px: 3 }}>
              <ListItemText sx={{ color: '#fff' }}>{id}</ListItemText>
            </ListItem>
            {children.map(({ id: childId, icon, active, url }) => (
              <ListItem disablePadding key={childId} component={Link} to={url}>
                <ListItemButton selected={active} sx={item} to={url}>
                  <ListItemIcon>{icon}</ListItemIcon>
                  <ListItemText>{childId}</ListItemText>
                </ListItemButton>
              </ListItem>
            ))}

            <Divider sx={{ mt: 2 }} />
          </Box>
        ))}
      </List>
    </Drawer>
  )
}
