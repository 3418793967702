// In this file, all Page components from 'src/pages` are auto-imported. Nested
// directories are supported, and should be uppercase. Each subdirectory will be
// prepended onto the component name.
//
// Examples:
//
// 'src/pages/HomePage/HomePage.js'         -> HomePage
// 'src/pages/Admin/BooksPage/BooksPage.js' -> AdminBooksPage

import { Set, Router, Route, Private } from '@redwoodjs/router'
import HomeLayout from 'src/layouts/HomeLayout'
import DashboardLayout from './layouts/DashboardLayout/DashboardLayout'

const Routes = () => {
  return (
    <Router>
      <Private unauthenticated="home">
        <Set wrap={DashboardLayout}>
          <Route path="/vendors/new" page={VendorNewVendorPage} name="newVendor" />
          <Route path="/vendors/{id:Int}/edit" page={VendorEditVendorPage} name="editVendor" />
          <Route path="/vendors/{id:Int}" page={VendorVendorPage} name="vendor" />
          <Route path="/vendors" page={VendorVendorsPage} name="vendors" />
        </Set>
      </Private>
      <Set wrap={HomeLayout}>
        <Route path="/" page={HomePage} name="home" />
      </Set>
      <Route notfound page={NotFoundPage} />
    </Router>
  )
}

export default Routes
