import { Link, routes } from '@redwoodjs/router'
import { useAuth } from '@redwoodjs/auth'

const HomeLayout = ({ children }) => {
  const { logIn, logOut, isAuthenticated, currentUser } = useAuth()
  console.log('Current User', currentUser)
  return (
    <>
      <header className="rw-header cs-padding">
        <a href="https://caribshopper.com/">
          <img
            className="site-logo"
            alt="Caribshopper"
            src="https://cdn.caribshopper.com/wp-content/uploads/2021/04/22115057/caribshopper-logo-wp.png"
          />
        </a>
        <div className="menu-items">
          {isAuthenticated ? (
            <>
              <Link to={routes.vendors()}>Dashboard </Link>
              <Link onClick={logOut}>Log out</Link>
              {/* <Link onClick={logOut}>{currentUser.email}</Link> */}
            </>
          ) : (
            <Link href="#" onClick={logIn}>
              Log in
            </Link>
          )}
        </div>
      </header>
      <main className="main">{children}</main>
    </>
  )
}

export default HomeLayout
